import {
  ICategoryCombo,
  IComboJourney,
  IProduct,
  ISubcategoryCombo,
} from '~/interfaces/IProduct';

export function getComboMinPrice(combo: IProduct): number {
  const { comboJourneys, products } = combo;

  if ((products || []).length) {
    const autoSelectComboPrice =
      products.reduce((prev, product) => prev + product.priceInCombo || 0, 0) ||
      0;

    return Number(autoSelectComboPrice.toFixed(2));
  }

  const hasComboJourneys = (comboJourneys || []).length > 0;
  if (!hasComboJourneys) return 0;

  const reduceJourneysMinPrice = (
    acc: number[],
    comboJourney: IComboJourney | ICategoryCombo | ISubcategoryCombo
  ): number[] => {
    const hasComboCategories =
      ((comboJourney as IComboJourney).categories || []).length > 0;

    if (hasComboCategories) {
      const categoriesMinPrices = (
        comboJourney as IComboJourney
      ).categories!.reduce(reduceJourneysMinPrice, []);

      const categoryMinPrice = Math.min(...categoriesMinPrices);

      return [...(acc || []), categoryMinPrice];
    }

    const hasComboSubcategories =
      ((comboJourney as ICategoryCombo).subcategories || []).length > 0;

    if (hasComboSubcategories) {
      const subcategoriesMinPrices = (
        comboJourney as ICategoryCombo
      ).subcategories!.reduce(reduceJourneysMinPrice, []);

      const subcategoryMinPrice = Math.min(...subcategoriesMinPrices);

      return [...(acc || []), subcategoryMinPrice];
    }

    const hasAutomaticComboProducts =
      (comboJourney.products || []).length > 0 &&
      comboJourney.type === 'automatic';

    if (hasAutomaticComboProducts) {
      const automaticComboProductsTotal = comboJourney.products!.reduce(
        (comboProductAcc, comboProduct) =>
          comboProductAcc +
          (comboProduct.priceInCombo || 0) * (comboProduct.quantity || 1),
        0
      );

      return [...(acc || []), automaticComboProductsTotal];
    }

    const hasComboProducts =
      (comboJourney.products || []).length > 0 &&
      (comboJourney.minQuantity || 0) > 0;

    if (hasComboProducts) {
      const comboProductsPrices = comboJourney.products!.map(
        (comboProduct) => comboProduct.priceInCombo || 0
      );

      const smallestComboProductPrice = Math.min(...comboProductsPrices);

      const journeyMinPrice =
        smallestComboProductPrice * (comboJourney.minQuantity || 1)!;

      return [...(acc || []), journeyMinPrice];
    }

    return acc || [];
  };

  const journeysMinPrices = comboJourneys.reduce(reduceJourneysMinPrice, []);
  const journeysMinPriceTotal =
    journeysMinPrices.reduce(
      (acc, minPrice) => (acc || 0) + minPrice || 0,
      0
    ) || 0;

  return Number(journeysMinPriceTotal.toFixed(2));
}
